import React, { FC, HTMLAttributes, CSSProperties, isValidElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCaretRight } from '@fortawesome/pro-regular-svg-icons';
import styles from './styles.module.scss';
import Tooltip from '../../../components/Tooltip';

export interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {
    actions?: boolean,
    align?: 'center' | 'left' | 'right',
    as?: 'td' | 'th',
    collapsing?: boolean,
    icon?: IconDefinition | JSX.Element,
    name?: string,
    onExpand?: () => void,
    primary?: boolean,
    responsive?: boolean,
    tooltip?: string,
}

const TableCell: FC<TableCellProps> = ({
    actions,
    align,
    as,
    children,
    className,
    collapsing,
    icon,
    name,
    onExpand,
    primary,
    responsive,
    tooltip,
    ...props
}): JSX.Element => {
    const Elem = as ? as : 'td';

    const style: CSSProperties = {};
    if (props.onClick) {
        style.cursor = 'pointer';
    }
    
    return (
        <Elem
            className={[
                collapsing && styles.collapsing,
                primary && styles.primary,
                actions && styles.actions,
                responsive === false && styles.notResponsive,
                align && styles[`${align}Align`],
                className,
            ].filter(o => o).join(' ')}
            style={style}
            {...props}
        >
            <div data-name={name} style={{ width: '100%' }}>
                {icon && (isValidElement(icon) ? icon : <FontAwesomeIcon icon={icon as IconDefinition} style={{ marginRight: children ? 5 : 0 }} />)}
                {children}
            </div>
            {primary && (
                <div className={styles.collapseIcon} onClick={onExpand}>
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
            )}
            {tooltip && (
                <Tooltip
                    content={tooltip}
                    direction="bottom"
                />
            )}
        </Elem>
    );
}

export default TableCell;
